<template>
  <b-modal
    id="modal-add-edit-acto-violencia"
    size="md"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    v-model="mostrarModal"
    dialog-class="modal-dialog-scrollable"
    v-if="mostrarModal"
    @close="handleModalClose"
  >
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"
          ><i class="fa fa-hand-paper"></i> Edición Acto de Violencia</span
        >
      </div>
    </template>
    <b-form id="frm-add-edit-acto-violencia">
      <h5>{{ cabeceraActoViolencia.nombreLocalVotacion }}</h5>
      <h5>
        <small>{{ cabeceraActoViolencia.direccionLocalVotacion }}</small>
      </h5>
      <h5>
        <small class="text-muted"
          >{{ cabeceraActoViolencia.nombreRegion }} /
          {{ cabeceraActoViolencia.nonbreSubRegion }}</small
        >
      </h5>
      <hr />
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha y hora</label><br />
          <input
            type="datetime-local"
            id="input-7"
            class="form-control"
            v-model="actoViolencia.horaEvento"
            placeholder=""
          />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">
            Describa brevemente el acto de violencia:</label
          >
          <textarea
            class="form-control"
            ref="input-8"
            id="input-8"
            v-model="actoViolencia.descripcion"
            placeholder=""
          ></textarea>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold"
            >Describa brevemente las medidas tomadas:</label
          >
          <textarea
            class="form-control"
            ref="input-9"
            id="input-9"
            v-model="actoViolencia.medidasTomadas"
            placeholder=""
          ></textarea>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-2" style="align-self: center;">
          <span class="font-weight-bold">Eliminar</span>
        </div>
        <div class="col-md-10">
          <b-form-checkbox
            id="checkbox-1"
            size="lg"
            v-model="actoViolencia.esEliminado"
          >
          </b-form-checkbox>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click.prevent="handleModalClose()"
      >
        <i class="fa fa-ban"></i> Cancelar
      </button>
      <button
        type="button"
        class="btn btn-primary font-weight-bold"
        @click.prevent="saveActoViolencia()"
      >
        <i class="fa fa-save"></i> Guardar
      </button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
import moment from "moment";
import Form from "../../view/pages/vue-bootstrap/Form.vue";

export default {
  name: "add-edit-acto-violencia",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    actoViolenciaId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    cabeceraActoViolencia: {},
    actoViolencia: {
      localVotacionFichaActosViolenciaId: null,
      localVotacionFichaId: null,
      horaEvento: null,
      descripcion: "",
      medidasTomadas: "",
      esEliminado: false,
    },
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      this.mostrarModal = false;
    },
    fetchActoViolencia: function(actoViolenciaId) {
      if (actoViolenciaId) {
        this.$http
          .get(this.apiRoute("Incident", "GetLocalVotacionActoViolenciaById"), {
            params: {
              LocalVotacionFichaActosViolenciaId: actoViolenciaId,
            },
          })
          .then(function(response) {
            this.cabeceraActoViolencia = response.body.data.cabecera;
            this.actoViolencia = response.body.data.actoViolencia;
          });
      }
    },
    saveActoViolencia: async function() {
      debugger;
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-acto-violencia")) {
        return this.sweetAlert(
          "Hay campos con valores incorrectos. Revise los datos ingresados."
        );
      }

      var model = {
        ...this.actoViolencia,
      };

      this.estaGuardando = true;
      await this.$http
        .post(
          this.apiRoute("Incident", "AddEditLocalVotacionActoViolencia"),
          model
        )
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
          }
        }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
      this.mostrarModal = false;
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.actoViolencia.localVotacionFichaActosViolenciaId = this.actoViolenciaId;
    this.initParsley();
    this.fetchActoViolencia(
      this.actoViolencia.localVotacionFichaActosViolenciaId
    );
  },
  destoyed: function() {},
};
</script>
