<template>
  <b-modal id="modal-acto-violencia" size="md" :no-close-on-backdrop="true" :no-close-on-esc="true" v-model="mostrarModal" dialog-class="modal-dialog-scrollable" v-if="mostrarModal" @close="handleModalClose">
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"><i class="la la-info-circle"></i> Detalle de acto de violencia</span>
      </div>
    </template>
    <b-form id="frm-detalle-acto-violencia">
      <h5>{{ actoViolenciaModel.nombreLocalVotacion }}</h5>
      <h5>
        <small>{{ actoViolenciaModel.direccionLocalVotacion }}</small>
      </h5>
      <h5>
        <small class="text-muted">{{ actoViolenciaModel.nombreRegion }} / {{ actoViolenciaModel.nonbreSubRegion }}</small>
      </h5>
      <hr />
      <!--<div class="row pt-1">
        <div class="col-md-12">
          <label class="font-weight-bold">Local de Votación</label>
          <b-form-input id="input-3" v-model="actoViolenciaModel.nombreLocalVotacion" disabled placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Dirección</label>
          <b-form-input id="input-4" v-model="actoViolenciaModel.direccionLocalVotacion" disabled placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Región</label>
          <b-form-input id="input-5" v-model="actoViolenciaModel.nombreRegion" disabled placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Sub - Región</label>
          <b-form-input id="input-6" v-model="actoViolenciaModel.nonbreSubRegion" disabled placeholder=""></b-form-input>
        </div>
      </div>-->
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha y hora</label>
          <b-form-input id="input-7" v-model="horaEventoActo" disabled placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Descripción</label>
          <!--<b-form-input id="input-8" v-model="actoViolenciaModel.descripcionActo" disabled placeholder=""></b-form-input>-->
          <textarea class="form-control" ref="input-8" id="input-8" v-model="actoViolenciaModel.descripcionActo" disabled placeholder=""></textarea>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Medidas tomadas</label>
          <!--<b-form-input
            id="input-9"
            v-model="actoViolenciaModel.medidasTomadasActo"
            disabled
            placeholder=""
          ></b-form-input>-->
          <textarea class="form-control" ref="input-9" id="input-9" v-model="actoViolenciaModel.medidasTomadasActo" disabled placeholder=""></textarea>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Imagen</label>
          <!-- <img :src="actoViolenciaModel.imagen" style="width:150px;height:auto;" /> -->
          <div v-if="actoViolenciaModel.imagenes">
            <CoolLightBox :items="actoViolenciaModel.coolboxImages" :index="actoViolenciaModel.coolboxIndex" :useZoomBar="true" :slideshow="true" @close="actoViolenciaModel.coolboxIndex = null"> </CoolLightBox>
            <div class="symbol-group symbol-hover" v-if="actoViolenciaModel.symbolImages.length > 0">
              <div v-for="(imagen, imageIndex) in actoViolenciaModel.symbolImages" :key="imagen.procesoElectoralMultimediaImagenId" @click="actoViolenciaModel.coolboxIndex = imageIndex">
                <div class="symbol symbol-50">
                  <img alt="" :src="apiResource(imagen.rutaArchivo)" />
                </div>
              </div>
              <div class="symbol symbol-50 symbol-circle symbol-light" v-if="actoViolenciaModel.masImagenes">
                <span class="symbol-label font-weight-bold" @click="actoViolenciaModel.coolboxIndex = 0">{{ actoViolenciaModel.masImagenes }}+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click.prevent="handleModalClose()"><i class="fa fa-ban"></i> Cancelar</button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
import moment from "moment";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "modal-acto-violencia",
  components: { CoolLightBox },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    actoViolencia: {
      type: Object,
    },
  },
  data: () => ({
    mostrarModal: true,
    actoViolenciaModel: {},
    horaEventoActo: null,
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }
      this.mostrarModal = false;
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.actoViolenciaModel = this.actoViolencia;
    this.horaEventoActo = moment(String(this.actoViolenciaModel.horaEventoActo)).format("DD/MM/YYYY h:mm A");
  },
  destoyed: function() {},
};
</script>
